import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export default unsafeHTML(`
<style type="text/css">
  .st0 {fill:#FFFFFF;}
  .st1 {fill:#1B7DC2;}

  @media print {
    .st0 {fill: #495154;}
  }
</style>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 775.3 186.2" style="enable-background:new 0 0 775.3 186.2;" xml:space="preserve">
  <g>
    <g>
      <path class="st0" d="M204.6,102.2c5.7,3.4,14.3,7.2,29.3,7.2c22.6,0,34.6-9.1,34.6-26.5c0-15.5-7.6-22-29.2-25.1
        c-13.9-1.9-22-4.9-22-14.4c0-9.1,6.7-13.7,19.9-13.7c9.1,0,16.7,2.2,22.6,6.4h3.4V25.8c-5.3-2.7-13.1-5.7-26.2-5.7
        c-20.6,0-32.4,9-32.4,24.7c0,14.6,8.3,21.7,28.5,24.4c14.2,2.1,21.8,5.1,21.8,15.2c0,7-3.8,15.2-21.9,15.2
        c-13.3,0-21.3-4.9-25.1-8.2h-3.3V102.2z"/>
    </g>
    <path class="st0" d="M368.8,70.3c-10.1-1.4-15.9-3.5-15.9-10.1c0-6.4,4.8-9.6,14.2-9.6c6.6,0,12.2,1.6,16.4,4.7l0.1,0.1h3.2v-8.5
      l-0.3-0.1c-3.5-1.8-9.4-4.2-19.6-4.2c-15.6,0-24.5,6.8-24.5,18.8c0,11.1,6.2,16.5,21.5,18.5c10.2,1.5,15.7,3.6,15.7,10.8
      c0,7.1-5.4,10.8-15.7,10.8c-9.8,0-15.7-3.7-18.2-6l-0.1-0.1h-3.1v8.7l0.2,0.1c3.8,2.3,10.3,5.4,22,5.4c17.1,0,26.1-6.9,26.1-20.1
      C390.9,77.5,385.1,72.5,368.8,70.3z"/>
    <path class="st1" d="M577.2,69.8h-33.5c1.8-11,8.2-17.2,17.8-17.2c10,0,15.5,4.7,16.2,16C577.8,69.2,577.8,69.8,577.2,69.8
      M588.5,76.2c0-0.6,0.1-1.6,0.1-2.4c0-19.7-9.6-30.5-27.1-30.5c-17.1,0-29,13.7-29,33.3c0,20.5,11.4,32.7,30.5,32.7
      c9.5,0,17.1-2.4,23.3-7.2c1.1-0.6,0.9-1.2,0.9-2.1v-5.3c0-0.6-0.4-1.2-1.1-1.2h-3.8c-0.8,0-1.5,0.3-1.8,0.7
      c-2.1,2.1-7,5.5-16.9,5.5c-13,0-19.6-6.8-20.3-20.7h42.7C587.8,79,588.5,78.2,588.5,76.2"/>
    <path class="st1" d="M530.7,52.3l-2.9-4.4c-0.5-0.8-0.7-1.4-1.9-1.2c-6.6-0.6-13.1,0.8-19.7,4.4v-5c0-0.9-0.4-1.7-1.3-1.7h-0.4
      l-7.3,1.7c-1.1,0.2-1.7,0.6-1.7,1.7V107c0,1.1,0.7,1.8,1.9,1.8h7c1.2,0,1.8-0.7,1.8-1.8l0-44.8c0.6-0.5,1.3-0.9,2-1.4
      c8.3-5.4,14.4-5.2,17.2-4.6c0.5,0.1,1.3,0,1.9-0.4l3.2-2C531,53.5,531,52.8,530.7,52.3z"/>
    <path class="st0" d="M273.3,43.7h12.5l18.4,50.9l19.1-50.9h12.2l-27.1,67.1c-4.8,11.8-12,19.2-21.6,22.2l-4.4-8.1
      c7.3-3.2,12.6-8.4,15.9-15.6L273.3,43.7z"/>
    <path class="st1" d="M405.6,108.9V20.1h36c27.7,0,41.6,14,41.6,42c0,31.2-13.9,46.8-41.6,46.8H405.6z M439.2,99.6
      c21.7,0,32.6-12.5,32.6-37.5c0-21.8-10.9-32.7-32.6-32.7h-22.1v70.2H439.2z"/>
    <path class="st1" d="M741.7,43.3c-7.5,0-14.9,2.3-22.1,6.7c-2.9-4.4-8-6.7-15-6.7c-8.1,0-16.2,2.6-23.9,7.9l0-0.2v-5.1
      c0-0.9-0.4-1.7-1.3-1.7h-0.4l-7.3,1.7c-1.1,0.2-1.7,0.6-1.7,1.7V107c0,1.1,0.7,1.8,1.9,1.8h7c1.2,0,1.8-0.7,1.8-1.8l0-45.1
      c8.1-6,15.2-8.9,21.7-8.9c5.9,0,9.5,1.6,9.5,9.4l0,44.6c0,1.1,0.7,1.8,1.9,1.8h6.9c1.2,0,1.8-0.7,1.8-1.8l0-45.3c0-1-0.1-2-0.2-3
      c6.2-3.8,11.8-5.8,17-5.8c5.9,0,9.5,1.6,9.5,9.4l0,44.6c0,1.1,0.7,1.8,1.9,1.8h6.9c1.2,0,1.8-0.7,1.8-1.8l0-45.3
      C759.7,49.7,753.5,43.3,741.7,43.3z"/>
    <path class="st1" d="M654.8,85.1V64.6c0-13.9-9.7-20.9-29.1-20.9c-1.8,0-3.7,0.1-5.6,0.3c0,0,0,0,0,0c-5.6,0.9-10.3,3-14.4,6.2
      c-1,0.5-0.9,1.1-0.9,2v5c0,0.6,0.4,1.2,1,1.2h3.5c0.7,0,1.4-0.3,1.7-0.7c1.2-1.2,3.6-3,7.6-4.1c2.3-0.2,4.7-0.4,7-0.4
      c11.7,0,17.6,3.5,17.6,10.6v6.1c-4.8-0.8-10.5-1.2-17-1.2c-17.6,0-26.5,6.7-26.5,20.1c0,13.5,8.2,20.3,24.5,20.3
      c7.2,0,13.5-1.4,18.9-4.1l0,2.1c0,1.2,0.8,1.9,2.1,1.9h7.5c1.3,0,1.9-0.8,1.9-1.9L654.8,85.1C654.8,85.1,654.8,85.1,654.8,85.1z
      M624.4,99.6c-8.7,0-13-3.7-13-11.2c0-7,5-10.6,15-10.6c6.8,0,12.5,0.4,17,1.2v14.4c0,0,0,0,0,0l0,1
      C638.6,97.9,632.3,99.6,624.4,99.6z"/>
  </g>
  <g>
    <path class="st0" d="M238.9,167.2c-2.4,0.8-5.1,1.2-7.9,1.2c-9.9,0-14.8-4.9-14.8-14.7c0-9.3,4.9-13.9,14.8-13.9
      c2.8,0,5.5,0.4,7.9,1.2v3.2c-2.4-0.9-4.9-1.4-7.5-1.4c-7.7,0-11.6,3.7-11.6,11c0,7.8,3.9,11.8,11.6,11.8c2.6,0,5.1-0.5,7.5-1.4
      V167.2z"/>
    <path class="st0" d="M242.1,147.4h4l5.9,16.2l6.1-16.2h3.9l-8.6,21.4c-1.5,3.8-3.8,6.1-6.9,7.1l-1.4-2.6c2.3-1,4-2.7,5.1-5
      L242.1,147.4z"/>
    <path class="st0" d="M265.3,139.9h3.7v8.5c1.6-0.7,3.3-1,5-1c6.6,0,9.9,3.3,9.9,10c0,7.2-3.5,10.8-10.3,10.8c-3,0-5.7-0.3-8.2-0.9
      V139.9z M269,164.6c1.2,0.4,2.7,0.6,4.4,0.6c4.6,0,6.9-2.6,6.9-7.9c0-4.4-2.1-6.7-6.3-6.7c-1.9,0-3.6,0.4-5,1.1V164.6z"/>
    <path class="st0" d="M305.5,158.8h-14c0,4.2,2.7,6.4,8,6.4c1.8,0,3.7-0.2,5.5-0.6v3c-1.8,0.4-4.1,0.6-6.7,0.6
      c-7,0-10.5-3.5-10.5-10.6c0-6.8,3.3-10.1,10-10.1C304.1,147.4,306.7,151.2,305.5,158.8z M291.5,155.8h10.6c0.2-3.6-1.5-5.5-4.9-5.5
      C293.6,150.3,291.7,152.2,291.5,155.8z"/>
    <path class="st0" d="M311.2,168.2v-20.8h2.5l0.7,2.7c1.8-1.8,4-2.7,6.6-2.7v3c-2.5,0-4.5,0.9-6,2.6v15.1H311.2z"/>
    <path class="st0" d="M324.5,167.2V164c2.3,0.8,4.7,1.2,7.2,1.2c3.3,0,5-1,5-3c0-1.8-1.1-2.8-3.3-2.8h-3.4c-4.4,0-6.5-2-6.5-6.1
      c0-4,2.9-5.9,8.6-5.9c2.5,0,4.9,0.3,7.2,1v3.2c-2.3-0.8-4.7-1.2-7.2-1.2c-3.5,0-5.2,1-5.2,3c0,1.8,1,2.8,3.1,2.8h3.4
      c4.6,0,6.9,2,6.9,6.1c0,4-2.9,5.9-8.6,5.9C329.2,168.2,326.9,167.9,324.5,167.2z"/>
    <path class="st0" d="M362,158.8h-14c0,4.2,2.7,6.4,8,6.4c1.8,0,3.7-0.2,5.5-0.6v3c-1.8,0.4-4.1,0.6-6.7,0.6
      c-7,0-10.5-3.5-10.5-10.6c0-6.8,3.3-10.1,10-10.1C360.6,147.4,363.2,151.2,362,158.8z M348,155.8h10.6c0.2-3.6-1.5-5.5-4.9-5.5
      C350.1,150.3,348.2,152.2,348,155.8z M357.5,138.4v3l-7.9,3v-3L357.5,138.4z"/>
    <path class="st0" d="M383.5,167.6c-1.7,0.4-3.6,0.6-5.5,0.6c-7.5,0-11.3-3.6-11.3-10.8c0-6.7,3.8-10,11.3-10c2,0,3.8,0.2,5.5,0.6v3
      c-1.7-0.4-3.4-0.6-5.1-0.6c-5.3,0-8,2.3-8,7c0,5.2,2.7,7.8,8,7.8c1.7,0,3.4-0.2,5.1-0.6V167.6z"/>
    <path class="st0" d="M405.9,147.4v20.8h-2.5l-0.6-2.7c-2.5,1.8-5.1,2.7-7.6,2.7c-4.8,0-7.2-2.6-7.2-7.7v-13h3.7v13.1
      c0,3.1,1.5,4.6,4.6,4.6c2.1,0,4.1-0.9,6-2.6v-15.1H405.9z"/>
    <path class="st0" d="M411.8,168.2v-20.8h2.5l0.7,2.7c1.8-1.8,4-2.7,6.6-2.7v3c-2.5,0-4.5,0.9-6,2.6v15.1H411.8z"/>
    <path class="st0" d="M428.8,139.9v3h-3.7v-3H428.8z M428.8,147.4v20.8h-3.7v-20.8H428.8z"/>
    <path class="st0" d="M434.8,144.1h2.3l0.8,3.4h4.8v3h-4.3v11.9c0,1.9,0.8,2.9,2.5,2.9h1.7v3H440c-3.5,0-5.2-1.9-5.2-5.6V144.1z"/>
    <path class="st0" d="M464.3,158.8h-14c0,4.2,2.7,6.4,8,6.4c1.8,0,3.7-0.2,5.5-0.6v3c-1.8,0.4-4.1,0.6-6.7,0.6
      c-7,0-10.5-3.5-10.5-10.6c0-6.8,3.3-10.1,10-10.1C462.9,147.4,465.5,151.2,464.3,158.8z M450.3,155.8h10.6c0.2-3.6-1.5-5.5-4.9-5.5
      C452.4,150.3,450.5,152.2,450.3,155.8z M459.8,138.4v3l-7.9,3v-3L459.8,138.4z"/>
    <path class="st0" d="M479.6,168.2v-28.3h3.7v12.5h17.1v-12.5h3.7v28.3h-3.7v-12.9h-17.1v12.9H479.6z"/>
    <path class="st0" d="M527.9,147.4v20.8h-2.5l-0.6-2.7c-2.5,1.8-5.1,2.7-7.6,2.7c-4.8,0-7.2-2.6-7.2-7.7v-13h3.7v13.1
      c0,3.1,1.5,4.6,4.6,4.6c2.1,0,4.1-0.9,6-2.6v-15.1H527.9z"/>
    <path class="st0" d="M533.8,139.9h3.7v8.5c1.6-0.7,3.3-1,5-1c6.6,0,9.9,3.3,9.9,10c0,7.2-3.5,10.8-10.3,10.8c-3,0-5.7-0.3-8.2-0.9
      V139.9z M537.5,164.6c1.2,0.4,2.7,0.6,4.4,0.6c4.6,0,6.9-2.6,6.9-7.9c0-4.4-2.1-6.7-6.3-6.7c-1.9,0-3.6,0.4-5,1.1V164.6z"/>
    <path class="st0" d="M565.9,154.1c0-9.6,4.7-14.4,14.2-14.4c9.3,0,14,4.8,14,14.4c0,9.5-4.7,14.3-14,14.3
      C570.6,168.4,565.9,163.6,565.9,154.1z M580.1,165.4c6.8,0,10.1-3.8,10.1-11.5c0-7.5-3.4-11.3-10.1-11.3c-6.9,0-10.3,3.8-10.3,11.3
      C569.8,161.6,573.2,165.4,580.1,165.4z"/>
    <path class="st0" d="M599,168.2v-20.8h2.5l0.7,2.7c2.5-1.8,5.1-2.7,7.6-2.7c4.8,0,7.2,2.6,7.2,7.7v13h-3.7v-13.1
      c0-3.1-1.5-4.6-4.6-4.6c-2.1,0-4.1,0.9-6,2.6v15.1H599z"/>
    <path class="st0" d="M639.5,158.8h-14c0,4.2,2.7,6.4,8,6.4c1.8,0,3.7-0.2,5.5-0.6v3c-1.8,0.4-4.1,0.6-6.7,0.6
      c-7,0-10.5-3.5-10.5-10.6c0-6.8,3.3-10.1,10-10.1C638.2,147.4,640.8,151.2,639.5,158.8z M625.6,155.8h10.6c0.2-3.6-1.5-5.5-4.9-5.5
      C627.7,150.3,625.8,152.2,625.6,155.8z"/>
  </g>
  <g>
    <path class="st1" d="M178.5,87.3c0-36.2-30.8-65.6-68.8-65.6c-36.3,0-66.1,26.9-68.6,60.9c0,0.8,0.1,1.6,0.1,2.3
      c2.8-29.3,28.7-52.3,60.2-52.3c33.4,0,60.5,25.8,60.5,57.6c0,31.8-27.1,57.6-60.5,57.6c-15.9,0-30.4-5.9-41.2-15.5
      c0.3,0.4,0.6,0.8,0.9,1.1c12.4,11.8,29.6,19.2,48.6,19.2C147.7,152.9,178.5,123.5,178.5,87.3"/>
    <path class="st1" d="M19.1,69.4C8.2,113,36.4,156.7,82,167c43.7,9.9,87.6-14.3,100.8-54.5c0.2-1,0.4-1.9,0.6-2.8
      c-12.2,34.5-50.2,55-88.1,46.4c-40.2-9.1-65-47.6-55.4-85.8C49.5,32,89.8,8.4,130,17.5c19.2,4.4,34.8,15.4,44.9,29.9
      c-0.3-0.5-0.5-1.1-0.8-1.6c-11.4-17.6-29.8-31.2-52.6-36.4C75.8-1,29.9,25.9,19.1,69.4"/>
    <path class="st1" d="M76.1,56.6C53.1,73.4,48.7,105,66.4,127c16.9,21.1,48,25.7,70.8,11.3c0.5-0.4,1-0.8,1.4-1.2
      c-20,12-46.7,7.8-61.4-10.5c-15.6-19.3-11.7-47.1,8.6-61.9c20.3-14.8,49.4-11.2,64.9,8.2c7.4,9.2,10.4,20.4,9.3,31.1
      c0.1-0.4,0.2-0.7,0.3-1.1c1.8-12.7-1.5-26.1-10.4-37.1C132.3,43.8,99.2,39.7,76.1,56.6"/>
  </g>
</svg>`)

