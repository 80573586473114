import { BaseElement, html, css } from 'Elements';
import { Sleep } from 'Utils';
import App from 'App';

const VERSION = '3.2.2';
const URL_JS = `assets/vendors/toastui/markdown/${VERSION}/dist/toastui-editor-all.min.js`;
const URL_JS_FR = `assets/vendors/toastui/markdown/${VERSION}/dist/i18n/fr-fr.js`;
const URL_CSS1 = `assets/vendors/toastui/markdown/${VERSION}/dist/toastui-editor.min.css`;
const URL_CSS2 = `assets/vendors/toastui/markdown/${VERSION}/dist/theme/toastui-editor-dark.min.css`;

class ToastEditorIframe extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
          padding-right:1px;
          position:relative;
          overflow:hidden;
          background-color: transparent; /* Rendre aussi le parent transparent */
        }

        iframe {
          box-sizing: border-box;
          width: 100%;
          height:0px;
          border:none;
          margin:0px;
          overflow:hidden;
          background-color:transparent;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      height: { type: String },
      placeholder: { type: String },
      allowImages: { type: Boolean, attribute: 'allow-images' },
      hideToolbar: { type: Boolean, attribute: 'hide-toolbar' },
      hideRenderer: { type: Boolean, attribute: 'hide-renderer' }
    }
  }

  constructor() {
    super();
    this.debug = true;
    this.value = '';
    this.placeholder = '';
    this.editor = null;
    this.firstTime = true;
    this.hideToolbar = false;
    this.onChange = this.onChange.bind(this);
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('height')) {
      while (!this.iframe) {
        this._log.debug('updated', 'waiting for iframe');
        await Sleep(50);
      }
      this._log.debug('updated', 'set iframe height', this.height);
      this.iframe.style.height = this.height;
    }

    if (changedProperties.has('value')) {
      //this.setEditorValue();
    }
  }

  _onThemeChange() {
    let el = this.editorContainer.getElementsByClassName("toastui-editor-defaultUI")[0];
    if (el) App.config.isDark ? el.classList.add("toastui-editor-dark") : el.classList.remove("toastui-editor-dark");
  }

  _onLanguageChanged(ev) {
    super._onLanguageChanged(ev);
    const lg = App.config.lg ? 'fr' : 'en';
    this.editor.i18n.setCode(lg);
    //@TODO: this does not work. We have to get the value of the editor, destroy it and recreate it with the new language
  }

  async firstUpdated() {
    await super.firstUpdated();
    this.iframe = this.shadowRoot.querySelector('iframe');
    this.iframe.srcdoc = this.getIframeContent();
    this.iframe.onload = async () => {
      const iframeDocument = this.iframe.contentDocument || this.iframe.contentWindow.document;
      await this.init(iframeDocument);
    };
  }

  onChange() {
    if (this.firstTime) return;
    this.value = this.editor.getMarkdown();
    this.dispatchEvent(new CustomEvent('input', { detail: this.value }));
  }

  async init(iframeDocument) {
    if (!this.editor) {
      const iframeWindow = iframeDocument.defaultView;
      const toastui = iframeWindow.toastui;
      this.editorContainer = iframeDocument.getElementById('editor');
      
      const defaultToolbarItems = [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['table', 'code', 'codeblock'],
        ['link'],
        ['scrollSync']
      ]

      if (this.allowImages) {
        defaultToolbarItems.push(['image']);
      }

      const hideModeSwitch = (this.hideToolbar ? true : false) || this.hideRenderer;

      const config = {
        el: this.editorContainer,
        initialEditType:'wysiwyg',
        height:'100%',
        usageStatistics: false,
        placeholder: this.placeholder || '',
        language: App.config.lg === 'fr' ? 'fr' : 'en',
        toolbarItems: defaultToolbarItems,
        hideModeSwitch,
        events:{
          load:async () => {
            this._log.debug('init: editor loaded');
            this.setEditorValue();
            this._onThemeChange();

            if (!this.hideToolbar) {
              await Sleep(1);
              const tb = this.editorContainer.getElementsByClassName("toastui-editor-toolbar");
              if (tb && tb.length > 0) tb[0].classList.add('visible');
            }
          },
          change: this.onChange
        }
      }

      this.editor = new toastui.Editor(config);      
      this.editor.i18n.setCode(App.config.lg ? 'fr' : 'en');

      //this.textarea = this.editor.mdEditor.el.querySelector('textarea');

      this.validator = iframeDocument.querySelector('input');

      this._onThemeChange();
    }
  }

  getIframeContent() {
    return `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>ToastUI Editor</title>
          <link rel="stylesheet" href="${URL_CSS1}">
          <link rel="stylesheet" href="${URL_CSS2}">
          <script src="${URL_JS}"></script>
          ${App.config.lg === 'fr' ? `<script src="${URL_JS_FR}"></script>` : ''}
          <style type="text/css">
            :root {
              color-scheme: light dark;  /* Support des deux schémas, mais n'applique rien automatiquement */
            }

            body, html {
              margin: 0;
              padding: 0;
              height: 100%;
              width: 100%;
              background-color: transparent;
              display:flex;
              flex-direction: column;
            }

            * {
              box-sizing: border-box;
            }

            #editor {
              background-color: transparent !important; /* Forcer la transparence */
            }

            .toastui-editor-toolbar {
              display: none !important;
            }

            .toastui-editor-toolbar.visible {
              display: block !important;
            }

          </style>
        </head>
        <body>
          <div id="editor"></div>
          <input type="text" name="bla" style="border:0px;height:0px;opacity:0;">
        </body>
      </html>
    `;
  }

  async setEditorValue() {
    if (!this.value) return;
    while (!this.editor) {
      this._log.debug('setEditorValue', 'waiting for editor, value', this.value);
      await Sleep(50);      
    }

    this.editor.setMarkdown('');
    if (this.value) {
      this.editor.setMarkdown(this.value);
      this._log.debug('setEditorValue', this.value);
    }
    this.firstTime = false;
  }

  setCustomValidity(msg) {
    this.validator.setCustomValidity(msg);
  }

  reportValidity() {
    this.validator.reportValidity();
  }

  render() {
    return html`<iframe style="background-color: transparent;" allowtransparency="true" frameborder="0"></iframe>`;
  }
}

customElements.define('toast-editor', ToastEditorIframe);

